import React from 'react';
import { AppService as App } from '../../services/app.js';

export default class Init extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            initUpdates: {}
        };

        App.on('initUpdate', (updates) => {
            this.setState({
                initUpdates: updates
            })
        });
    }

    componentDidMount() {
        // Setup App service
        App.init(this.props.match.params.id).then(() => {
            // Redirect user based on login status
            if (App.user.isLoggedIn()) {
                // Has the user accepted the terms and conditions?
                App.user.hasAcceptedTerms().then(() => {
                    let redirect = "/home";
                    if (this.props.location && this.props.location.state && this.props.location.state.redirect && this.props.location.state.redirect !== "/") {
                        redirect = this.props.location.state.redirect;
                    }

                    this.props.history.push(redirect);
                }, (terms) => {
                    this.props.history.push({
                        pathname: "/terms",
                        state: {
                            terms: terms
                        }
                    });
                }); 
            } else {
                this.props.history.push("/login")
            }
        }, (error) => {
            console.error(error);
            if (error) {
                alert(error);
            }

            // Logout user or redirect to login page
            if (App.user.isLoggedIn()) {
                App.user.logout();
            } else {
                this.props.history.push("/login")
            }
        });
    }

    render() {
        return (
            <div id="init">
                <div className="loader">
                    <h4 className="text-center">Updating app data</h4>
                </div>
                <div className="updates">
                    {
                        this.state.initUpdates ?
                            Object.keys(this.state.initUpdates).map((key) => {
                                var status = '...';
                                if (this.state.initUpdates[key] === 1) {
                                    status = 'loaded'
                                } else if (this.state.initUpdates[key] === 2) {
                                    status = 'failed'
                                }
                                return (
                                    <div key={key} style={{marginTop: 3, fontSize: 10, color: '#ffffff'}}>
                                        {key} [{status}]
                                    </div>
                                )
                            })
                        : null
                        }
                </div>
            </div>
        );
    }
}