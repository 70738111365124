import React from 'react';
import Button from '../includes/Button';

import {AppService as App} from '../../services/app.js';
import ContentBlock from "../includes/ContentBlock";
import FlatList from "flatlist-react";
import {ProductItem} from "../includes/ProductItem";
import {Table, Col, Row} from "react-bootstrap";

export default class Order extends React.Component {
    constructor(props) {
        super(props);

        this.updateQuantity = this.updateQuantity.bind(this);

        this.state = {
            products: App.products.get()
        }
    }

    updateQuantity(product, quantity) {
        console.log("Order update", product, quantity);
        App.wholesaler.order.update(product, quantity);

        this.setState({
            products: App.products.get()
        });
    }

    render() {
        let specialOffers = this.state.products.filter((product) => product.metadata.special_offer),
            orderValid = !App.wholesaler.order.check();

        return (
            <div id="order-new">
                <ContentBlock name="Order" type="background" />

                <div className="container">
                    <Row>
                        <Col>
                            <h3 id="page-title">Order</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={9}>
                            <div className="block">
                                <div className="content p-4">
                                    <Table striped borderless responsive>
                                        <thead>
                                            <tr>
                                                <th colspan="2">Product list</th>
                                                <th>Price</th>
                                                <th>Pallets</th>
                                                <th colspan="2">Order quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                specialOffers.length ?
                                                    <FlatList
                                                        list={specialOffers}
                                                        renderItem={
                                                            (item) => (
                                                                <ProductItem
                                                                    item={item}
                                                                    columns={['image', 'title', 'price', 'pallets', 'info', 'qty']}
                                                                    onUpdate={this.updateQuantity}
                                                                />
                                                            )
                                                        }
                                                        keyExtractor={item => item.id}
                                                    />
                                                : ""
                                            }

                                            <FlatList
                                                list={this.state.products.filter((product) => !product.metadata.special_offer)}
                                                renderItem={
                                                    (item) => (
                                                        <ProductItem
                                                            item={item}
                                                            columns={['image', 'title', 'price', 'pallets', 'info', 'qty']}
                                                            onUpdate={this.updateQuantity}
                                                        />
                                                    )
                                                }
                                                keyExtractor={item => item.id}
                                            />
                                        </tbody>
                                    </Table>
                                    <div className="table-footer sticky ">
                                        <div className="container">
                                            <Row>
                                                <Col md={9} xs={6} >
                                                    <Row>
                                                        <Col md="auto">
                                                            <div className="white bold">Minimum order (Pallets): { App.wholesaler.getMinPallets() }</div>
                                                        </Col>
                                                        <Col md="auto">
                                                            <div className={!orderValid ? "textblock bg-danger" : "textblock" }>Total order: { App.wholesaler.order.getPallets() }</div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    <Button title="Review order" theme="secondary" onClick={() => this.props.history.push('/order/review')} />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="block">
                                <div className="content p-4">
                                    <ContentBlock name="Order sidebar 1"/>
                                </div>
                            </div>
                            <div className="block">
                                <div className="content p-4">
                                    <ContentBlock name="Order sidebar 2"/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}
