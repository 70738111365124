import React from 'react';
import {ProductItem} from "../includes/ProductItem";

import { AppService as App } from '../../services/app.js';
import { Utils } from '../../services/utils.js';
import FlatList from "flatlist-react";
import {Col, Container, Nav, Row, Table} from "react-bootstrap";
import ContentBlock from "../includes/ContentBlock";
import Button from "../includes/Button";
import {Link} from "react-router-dom";
import SettingService from "../../services/settings";

export default class HistoryOrder extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            order: null
        }

        let orderId = this.props.match.params.id;

        App.wholesaler.history.getOrder(orderId).then((order) => {
            console.log(order);
            this.setState({
                order: order
            });
        });
    }

    render() {
        if (!this.state.order) {
            return "Loading...";
        }


        return (
            <div id="order-new">
                <ContentBlock name="Order" type="background" />
                <Container>
                    <Row>
                        <Col>
                            <h3 id="page-title">Order #{ this.state.order.ref }</h3>
                            <div className="block block--history-order">
                                <div className="content p-4">
                                    <Row>
                                        <Col>
                                            {
                                                !this.state.order ?
                                                    <p>Loading history information...</p>
                                                :
                                                    Object.keys(this.state.order.products).length ?

                                                        <Table striped borderless responsive>
                                                            <thead>
                                                            <tr>
                                                                <th colSpan="2">{Utils.dateUntil(this.state.order.order_date, true)}</th>
                                                                <th>Price</th>
                                                                <th>Order quantity</th>
                                                                <th>Pallets</th>
                                                                <th>Total</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <FlatList
                                                                list={this.state.order.products}
                                                                renderItem={
                                                                    (item) => (
                                                                        <ProductItem
                                                                            item={item}
                                                                            columns={['image', 'title', 'price', 'qty', 'pallets', 'total' ]}
                                                                            onUpdate={this.updateQuantity}
                                                                            history={this.state.order}
                                                                        />
                                                                    )
                                                                }
                                                                keyExtractor={item => item.id}
                                                            />
                                                            </tbody>
                                                        </Table>
                                                    :
                                                        <p >No products in order</p>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="address icon">
                                                <h5>Delivery address:</h5>
                                                {App.wholesaler.details.name}<br />
                                                {App.wholesaler.details.address_line_1}<br/>
                                                {App.wholesaler.details.address_line_2}<br/>
                                                {App.wholesaler.details.city}<br/>
                                                {App.wholesaler.details.eircode}<br />
                                                {App.wholesaler.details.account_no}<br />
                                            </div>

                                             <div className="address icon">
                                                <h5>Delivery date:</h5>
                                                <p>{Utils.dateUntil(this.state.order.delivery_date)}</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="reference icon"><h5>Your reference:</h5></div>
                                            <p>{ this.state.order.user_reference }</p>
                                        </Col>
                                        <Col>
                                            <div className="notes icon"><h5>Your delivery notes:</h5></div>
                                            <p>{ this.state.order.delivery_note }</p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <h4>Summary</h4>
                            <div className="block">
                                <div className="content p-4">
                                    <Row className="align-items-center">
                                        <Col className="align-middle">
                                            <Link to="/history">Back to overview</Link>
                                        </Col>
                                        <Col md={6}>
                                            <Table size="sm" borderless responsive>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            Total order quantity:
                                                        </td>
                                                        <td>
                                                            { App.wholesaler.order.getPallets(null, this.state.order.products) } pallets
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Sub total:
                                                        </td>
                                                        <td>
                                                            { Utils.formatCurrency(this.state.order.total) }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Vat ({ this.state.order.vat * 100 }%):
                                                        </td>
                                                        <td>
                                                            {
                                                                Utils.formatCurrency(this.state.order.total * this.state.order.vat)
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>Total price:</strong>
                                                        </td>
                                                        <td>
                                                            <strong>{
                                                                Utils.formatCurrency(this.state.order.total * (1 + this.state.order.vat))
                                                            }</strong>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col>
                                            <div
                                                className="btn btn--secondary btn--icon btn--order"
                                                onClick={() => { App.wholesaler.order.createNew(this.state.order); this.props.history.push("/order")}}
                                            >
                                                    Order again
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}