import packageJson from '../../package.json';
import { Cache } from 'react-native-cache';
import AsyncStorage from '@react-native-community/async-storage';

import { ApiService as Api } from './api.js';
import { UserService as User } from './user.js';
import { WholesalerService as Wholesaler } from './wholesaler.js';
import { ProductsService as Products } from './products.js';
import { AnalyticsService as Analytics } from './analytics.js';

Date.prototype.getWeek = (date = null, wkc = false) => {
    var curDate = new Date();
    var d = date == null ? new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDay()) : new Date(date);

    var months = [];

    if(date != null && !wkc){
        var weekDate = new Date(d.getFullYear(), d.getMonth(), d.getDate() + (d.getDay() == 0 ? -6 : 1)-d.getDay());

        return weekDate.getDate() +" "+ months[weekDate.getMonth()];
    }

    if(date == null || wkc){
        var secs = ((60*60)*24)*1000;
        var firstDayOfYear = new Date(d.getFullYear(), 0, 1);
        var pastDaysOfYear = (d - firstDayOfYear) / secs;

        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    }
}

export class AppService {
    static init(wholesalerID) {
        AppService.loaded = true;
        AppService.platform = 'web';
        AppService.version = packageJson.version;

        // local, dev, alpha, lite, black
        var tracks = {
            "local": "http://localhost:8000",
            "acc-starlink": "https://acc-starlink.heineken.ie/admin",
            "prod": "https://starlink.heineken.ie/admin"
        };
        
        var endpoint = window.location.host;

        if(endpoint.includes(".")) {
            if (endpoint.split(".")[0] == 'www') {
                endpoint = endpoint.split(".")[1];
            } else {
                endpoint = endpoint.split(".")[0];
            }
        }
        
        for(var track in tracks) {
            if(endpoint == track) {
                AppService.track = track;
                break;
            }
            
            if (endpoint.includes('local') || endpoint.includes('127')) {
                AppService.track = "local";
                break;
            }
            
            if(endpoint == "starlink") {
                AppService.track = "prod";
                break;
            }
        }

        AppService.baseURI = tracks[AppService.track];

        // Stop app if invalid track
        if (!AppService.baseURI) {
            alert("Invalid track");
            return new Promise(() => {});
        }

        switch (AppService.track) {
            case 'prod':
                AppService.firebaseConfig = {
                    apiKey: "AIzaSyBZDVvtoztgXW12Cboa2kXTRz1NgzBJaI4",
                    projectId: "heineken-starlink",
                    messagingSenderId: "503344744879",
                    appId: "1:503344744879:web:647bca0af1aa86a695475a",
                    measurementId: "G-M0YS1XT4JB"
                };
                break;
            default: // Default to dev firebase
                AppService.firebaseConfig = {
                    apiKey: "AIzaSyCmwx9vMj6fV012YKpMJ006qIB51pNQsZQ",
                    projectId: "starlink-dev",
                    messagingSenderId: "981673586036",
                    appId: "1:981673586036:web:cf24dd3bc024ddd738d021",
                    measurementId: "G-H3TJEDGXQH"
                };
        }

        AppService.analytics = Analytics;

        if (typeof this.callbacks === 'undefined') {
            this.callbacks = [];
        }
        AppService.initUpdates = {};

        AppService.cache = new Cache({
            namespace: "starlink:" + AppService.track,
            policy: {
                maxEntries: 50000
            },
            backend: AsyncStorage
        });

        AppService.api = Api;
        AppService.user = new User();
        AppService.headerData = null;
        AppService.headerTruck = null;


        AppService.date = (date = null) => {
            if(date == null){
                var curDate = new Date();
                return new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDay());
            } else {
                return new Date(date);
            }
        }

        AppService.products = new Products();
        AppService.wholesaler = new Wholesaler();

        // Promise currently only delaying app load until user is initialised
        return AppService.user.setup().then(() => {
            console.log("user setup");
            if(AppService.user.isInactive()) {
                throw "inactive";
            }

            if (wholesalerID) {
                AppService.user.setCurrentWholesaler(wholesalerID);
            }

            var setupPromises = [
                AppService.products.setup(),
                AppService.wholesaler.setup()
            ];

            return Promise.all(setupPromises).then(() => {
                return AppService.wholesaler.setup();
            }).then(() => {
                return new Promise(resolve => setTimeout(resolve, 1000)); // sleep for 1s
            });
        }, (error) => {
            throw error;
        });
    };

    static initUpdate(event, status) {
        if (!event) {
            this.initUpdates = [];
        } else {
            this.initUpdates[event] = status;
        }

        if (this.callbacks['initUpdate']) {
            this.callbacks['initUpdate'](this.initUpdates);
        }
    }

    static on(event, callback) {
        if (typeof this.callbacks === 'undefined') {
            this.callbacks = [];
        }

        this.callbacks[event] = callback;

        return this;
    }
}