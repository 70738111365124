/* eslint-disable */
import React from 'react';

import Button from './Button';

import { AppService as App } from '../../services/app.js';

export class PopUp extends React.Component {
    render(){
        if (!this.props.isVisible) return null;

        let content = this.renderContent();

        return (
            <div className={"popup popup--" + this.props.popupType}>
                <div id="popup-filter" onClick={() => this.props.onDismiss()}>
                </div>

                <div id="popup-box">
                    {
                        this.state.title || this.props.modalTitle ?
                            <h5 className="text-center">{this.state.title ? this.state.title : this.props.modalTitle}</h5>
                        : null
                    }
                    { content }
                </div>
            </div>
        )
    }
}