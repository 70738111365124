import React from 'react';

import {IconButton} from '../includes/IconButton';
import Button from '../includes/Button';
import {PopUpToFrom} from '../includes/PopUpToFrom';
import { Utils } from '../../services/utils.js';

import {AppService as App} from '../../services/app.js';
import {Table} from "react-bootstrap";
import FlatList from "flatlist-react";
import {ProductItem} from "../includes/ProductItem";
import ContentBlock from "../includes/ContentBlock";
import {Link} from "react-router-dom";
import {Row, Col} from "react-bootstrap";

export default class History extends React.Component {
    constructor(props) {
        super(props);

        var curWeek = App.date().getWeek();

        this.state = {
            open: null,
            curWeek,
            history: [],
            lastOrder: false,
            loading: true,
        }

        App.on('netinfo', (info) => {
            this.setState({
                netinfo: info
            });
        });

        this.loadHistory = this.loadHistory.bind(this);

        this.loadHistory();

        this.loadLatest = this.loadLatest.bind(this);

        this.loadLatest();
        this.refresh = this.refresh.bind(this);
    }

    refresh() {
        this.loadHistory();
    }

    loadLatest() {
        App.wholesaler.history.getOrders().then(history => {
            this.setState({
                lastOrder: history.shift()
            })
        });
    }

    loadHistory() {
        App.wholesaler.history.getOrders().then((history) => {
            this.setState({
                history,
                loading: false
            });
        }, (err) => {
            this.setState({
                loading: false
            });
        });
    }

    render() {
        return (
            <div>
                <ContentBlock name="Order" type="background" />
                <div className="container">

                    <Row>
                        <Col>
                            <h3 id="page-title">Order history</h3>
                        </Col>
                    </Row>
                    <Row className="row">
                        <Col lg={9}>

                            {
                                this.state.lastOrder ?
                                    <div className="block block--history">
                                        <div className="content p-4 history-list history-list--latest">
                                            <div className="header-circle header-circle--clock">Latest order</div>

                                            <Link to={"history/order/" + this.state.lastOrder.id} className="row">
                                                <Col xs lg="2">
                                                    <div
                                                        className="date">{Utils.getDateString(this.state.lastOrder.order_date, false)}</div>
                                                </Col>
                                                <Col className="order-details">
                                                    <div className="order-ref">Order #{this.state.lastOrder.ref}</div>
                                                    <div
                                                        className="order-total">{Utils.formatCurrency(this.state.lastOrder.total)}</div>
                                                </Col>
                                                <Col>
                                                    <div className="order-progress">{this.state.lastOrder.status}</div>
                                                </Col>
                                            </Link>

                                        </div>
                                    </div>
                                : null
                            }

                            <div className="block block--history">
                                <div className="content p-4 history-list">
                                    <FlatList
                                        list={this.state.history.slice(1)}
                                        renderItem={
                                            (item) => (
                                                <Link to={"history/order/" + item.id} className="row">
                                                    <Col xs lg="2"><div className="date">{ Utils.getDateString(item.order_date, false) }</div></Col>
                                                    <Col className="order-details">
                                                        <div className="order-ref">Order #{ item.ref }</div>
                                                        <div className="order-total">{ Utils.formatCurrency(item.total) }</div>
                                                    </Col>
                                                    <Col>
                                                        <div className="order-progress">{ item.status }</div>
                                                    </Col>
                                                </Link>
                                            )
                                        }
                                        keyExtractor={item => item.id}
                                        renderWhenEmpty={() => <div>No orders have been placed</div>}
                                    />
                                </div>
                            </div>
                   
                        </Col>
                        <Col>
                            <div className="block">
                                <div className="content p-4">
                                    <div className="header-circle header-circle--ship">Ship to address</div>
                                    <strong>{App.wholesaler.details.name}</strong><br />
                                    {App.wholesaler.details.address_line_1}<br/>
                                    {App.wholesaler.details.address_line_2}<br/>
                                    {App.wholesaler.details.city}<br/>
                                    {App.wholesaler.details.eircode}<br />
                                    Account number {App.wholesaler.details.account_no}
                                </div>
                            </div>
                            <div className="block">
                                <div className="content p-4">
                                    <ContentBlock name="Order sidebar 1"/>
                                </div>
                            </div>
                            <div className="block">
                                <div className="content p-4">
                                    <ContentBlock name="Order sidebar 2"/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}