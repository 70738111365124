import React from "react";
import {Utils} from '../../services/utils';

export class DateInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            label: this.props.label ? this.props.label: "",
            visible: false,
            onChange: this.props.onChange,
            value: this.props.value,
            min: this.props.min,
            max: this.props.max
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            label: nextProps.label ? nextProps.label: "",
            onChange: nextProps.onChange,
            value: nextProps.value,
            min: nextProps.min,
            max: nextProps.max
        })
    }

    render() {
        let calendarIcon = require('../../assets/icons/SmartPub-calendar-range-icon.svg');

        return (
            <div id="calendar-to" className="calendar-container">
                <label
                    className={this.state.value ? 'calendar-label--hidden' : ''}
                    id="calendar-to-label"
                    style={{backgroundImage: `url(${calendarIcon})`}}
                >
                    { this.state.label }
                </label>
                <input
                    type="date"
                    value={Utils.getDateString(this.state.value)}
                    min={Utils.getDateString(this.state.min)}
                    max={Utils.getDateString(this.state.max)}
                    onChange={e => {
                        this.setState({ visible: false, value: e.target.value });
                        this.state.onChange(e.target.value);
                    }}
                />
            </div>
        )
    }
}