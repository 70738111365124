import React from 'react';
import { AppService as App } from '../../services/app.js';

export default class Button extends React.Component {
	constructor(props){
		super(props);

		this.setup(props);
	}

	setup(props) {
		this.disabled = false;

		var classes = ["btn"];

		classes.push("btn-" + (props.size ? props.size : 'primary'));

		if (props.disabled) {
			classes.push("disabled");
			this.disabled = true;
		}

		classes.push(props.theme ? props.theme : "green");

		if (props.borderColor)  {
			classes.push("border-"+ props.borderColor);
		}

		if (props.textColor)  {
			classes.push("text-"+ props.textColor);
		}

		if (props.className) {
			classes.push(props.className);
		}

		// if (this.props.permission && !App.user.hasPermission(this.props.permission)) {
		// 	classes.push("btn-disabled");
		// 	this.disabled = true;
		// }

		this.className = classes.join(" ");

		this.activityColor = "#ffffff";

		if (props.theme === "light" || props.theme === "primary"){
			this.activityColor = "#1fa6a6";
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setup(nextProps);
	}

	render(){
		return (
			<button
				id={this.props.id}
				type={this.props.type || "button"}
				className={this.className}
				onClick={!this.disabled ? (this.props.onClick || null) : null}
				tabindex={this.props.disableTab ? "-1" : "0"}
			>
				{
					!this.props.loading ? 
						this.props.title || this.props.value || this.props.text || this.props.children || "Button"
					:
						"Loading..."
				}
			</button>
		)
	}
}