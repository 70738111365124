import React from 'react';
import { Link } from "react-router-dom";

import Button from '../includes/Button';
import LoginLayout from '../includes/LoginLayout';

import { AppService as App } from '../../services/app.js';

export default class LoginForgot extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            enteredEmail: null,
            loading: false,
            netinfo: {
                online: true
            }
        }

        this.request = this.request.bind(this); //bind this in order to access the state inside the function
    }

    request() {
        this.setState({ loading: true });

        App.user.request(this.state.enteredEmail).then(() => {
            console.log('Requested');
            this.setState({ loading: false });

            // Redirect user to homepage
            alert("Request submitted. You will recieve an email in due course");
            this.props.history.push("/");
        }, (error) => {
            console.log(error);
            alert(error);
            this.setState({ loading: false });
        });
    }

    render() {
        var content = (
            <div className="form">
                <img src={require('../../assets/icons/Starlink-logo.png')} alt="SmartPub logo" id="logo" />
                <p className="text-center">
                    Submit your details below
                    <br />
                    You will be sent a new password via email
                </p>
                <form method="POST" action="#" onSubmit={(e) => e.preventDefault()}>
                    <div className="form-group row">
                        <input
                            placeholder="Email"
                            type="text"
                            value={this.state.enteredEmail}
                            onChange={(e) => this.setState({enteredEmail: e.target.value})}
                        />
                    </div>
                    <div className="form-group row">
                        <Button
                            text="Submit"
                            theme="green"
                            onClick={this.request}
                            loading={this.state.loading}
                        />
                    </div>
                </form>
                <Link to="/login" className="text-center">{"<"} Back to login</Link>
            </div>
        );

        return (
            <LoginLayout content={content}/>
        );
    }
}