import React from 'react';
import { IconButton } from './IconButton';
import {Container, Row, Col} from "react-bootstrap";

export class Footer extends React.Component {
    render() {
        return (
        	<footer id="footer">
	            <Container>
	               	<Row>
	               		<Col lg={true}>
	               			<p>©2020 copyright HEINEKEN HOLDING N.V. All rights reserved.</p>
	               		</Col>
	               		<Col lg={true}>
	               			<a href="https://www.heinekenireland.ie" className="logo" target="_blank"></a>
	               		</Col>
	               		<Col lg={true}>
							<Row>
								<Col>
									<a href="https://smartpub.ie/static/media/Smartpub%20Terms%20of%20Service.60bce514.pdf" target="_blank">Legal</a>
								</Col>
								<Col>
									<a href="https://www.heinekenireland.ie/Cookie-Policy/Cookie-Policy.html" target="_blank">Cookies</a>
								</Col>
								<Col>
									<a href="https://www.heinekenireland.ie/Privacy-Policy/" target="_blank">Privacy</a>
								</Col>
							</Row>
	               		</Col>
	               	</Row>
	            </Container>
            </footer>
        );
    }
}