import { AppService as App } from './app.js';

export class FeedbackService {

    static submit(comment) {
        if (!comment) {
            return {
                message: "Missing details",
                description: "Comment or request is required",
                type: "danger",
            };
        }

        var data = {
            comment: comment
        };

        console.log(data);

        return App.api.request('feedback', data, "POST");
    }

}