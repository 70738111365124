import React from 'react';
import { Link } from "react-router-dom";

import Button from '../includes/Button';
import LoginLayout from '../includes/LoginLayout';

import { AppService as App } from '../../services/app.js';

export default class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            enteredUser: "",
            enteredPass: "",
            loading: false
        }

        this.login = this.login.bind(this); //bind this in order to access the state inside the function
    }

    login() {
        this.setState({ loading: true });

        console.log(App.user);

        var login = App.user.login(this.state.enteredUser, this.state.enteredPass);

        Promise.resolve(login).then(() => {
            console.log('Logged in');
            this.setState({ loading: false });

            // Redirect user to homepage
            this.props.history.push({
                pathname: "/",
                state: {
                    redirect: "/home"
                }
            });
        }, (error) => {
            console.log('error', error);
            alert(error);
            this.setState({ loading: false });
        });
    }

    render(){
        var content = (
            <div className="form">
                <img src={require('../../assets/icons/Starlink-logo.png')} alt="SmartPub logo" id="logo" />
                <form method="POST" action="#" onSubmit={(e) => e.preventDefault()}>
                    <div className="form-group row">
                        <input
                            placeholder="Email"
                            type="text"
                            value={this.state.enteredUser}
                            onChange={(e) => this.setState({enteredUser: e.target.value})}
                        />
                    </div>
                    <div className="form-group row">
                        <input
                            placeholder="Password"
                            type="password"
                            value={this.state.enteredPass}
                            onChange={(e) => this.setState({enteredPass: e.target.value})}
                        />
                    </div>
                    <div className="form-group row">
                        <Button
                            type="submit"
                            text="Login"
                            theme="green"
                            onClick={(e) => this.login()}
                            loading={this.state.loading}
                        />
                    </div>
                </form>
                <Link to="/forgot" className="text-center">Forgot password ></Link>
            </div>
        );

        return (
            <LoginLayout content={content}/>
        );
    }
}