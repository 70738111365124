import React from 'react';

import Button from '../includes/Button';

import { AppService as App } from '../../services/app.js';

export default class Terms extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            terms: props.location.state.terms
        }

        this.acceptTerms = this.acceptTerms.bind(this);
    }

    acceptTerms() {
        App.user.acceptTerms().then(() => {
            this.props.history.push("/home");
        });        
    }

    render() {
        return (
            <div id="terms">
                <div className="container row">
                    <div className="col-md-12">
                        <div id="terms-content">
                            <p>{this.state.terms}</p>
                        </div>
                        
                        <Button
                            title="Accept terms and conditions"
                            onClick={this.acceptTerms}
                        />
                    </div>
                </div>
            </div>
        );
    }
}