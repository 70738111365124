import React from 'react';
import { Footer } from './Footer';

import { AppService as App } from '../../services/app.js';

export default class Page extends React.Component {
	constructor(props){
		super(props);

		this.state = {
		    allowRender: false
        }
	}

    componentWillMount() {
	    if (this.props.type !== 'Init' && !App.loaded) {
	        console.error('No entry', this.props.type, this.props.location.pathname);
            this.props.history.push({
                pathname: "/",
                state: {
                    redirect: this.props.location.pathname
                }
            });

            return;
        }

        this.setState({
            allowRender: true
        });

        // Send analytics
        if (App.analytics) {
            App.analytics.setScreen(this.props.type);
        }
    }

	render() {
	    if (!this.state.allowRender) return "";

		return (
    		<div>
                <div id="page-wrapper" className={"page--" + this.props.type.toLowerCase() + (this.props.footer ? " has-footer" : "") + (this.props.header ? " has-header" : "")}>
                    {
                        this.props.header ?
                        this.props.header
                            :
                        null
                    }

                    {this.props.page}
                </div>
                {
                	this.props.footer ?
            		<Footer {...this.props} />
            	       :
            		null
                }
    		</div>
		);
	}
}