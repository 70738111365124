import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import ContentBlock from "../includes/ContentBlock";


export default class Content extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentWillMount() {
        this.lookupPage(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.lookupPage(nextProps);
    }

    lookupPage(props) {
        let page;

        switch(props.match.params.page) {
            case 'collections':
                page = 'Amendments and collections';
                break;
            case 'about':
                page = 'About HEINEKEN';
                break;
        }

        this.setState({
            page
        });
    }

    render() {
        return (
            <React.Fragment>
                <ContentBlock name="Order" type="background" />
                <Container>
                    <Row>
                        <Col>
                            <h3 id="page-title">{ this.state.page }</h3>
                            <div className="block">
                                <div className="content p-4">
                                    <ContentBlock name={this.state.page}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}