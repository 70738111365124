import { OrderService as Order } from './order.js';
import { HistoryService as History } from './history.js';
import { WholesalerMetaService as WholesalerMeta } from './wholesalerMeta.js';
import { AppService as App } from './app.js';
import { ApiService as Api } from './api.js';
import { Utils } from './utils.js';
import SettingService from "./settings";

export class WholesalerService { 

	constructor() {
        this.order = new Order();
        this.history = new History();
        this.meta = new WholesalerMeta();
	}

    setup() {
        App.initUpdate('Wholesaler details', 0);
        return this._loadWholesalerInfo()
            .then(() => this.meta.setup())
            .catch((e) => {
                console.error('errrrrr', e);
                // User probably not logged in
            });
    }

    save() {
        App.cache.setItem("wholesaler", this.details, (err) => { });
    }

    update(data) {
        Object.keys(data).forEach((key) => {
            this.details[key] = data[key];
        });

        return Api.request("wholesaler", data, "PATCH");
    }

    getDeliveryDates() {
        var dates = [];

        console.log('this.nextDay', this.nextDay);

        // Do they qualify for next day delivery?
        if (App.wholesaler.order.getContainerStatus().nextDay) {
            // Find first valid next day delivery date
            console.log('this.nextDay', this.nextDay);
            this.nextDay.some((date) => {
                console.log('DATE', date);
                if (date.cutoffDate > new Date()) {
                    dates.push(date);
                    return true;
                }
            });
        }

        for (var i = 1; i <= 3; i++) {
            var delivery = App.wholesaler.meta.get('delivery_date' + i),
                cutoff = App.wholesaler.meta.get('cut_off' + i);

            if (!delivery || Utils.isDateBeforeToday(cutoff)) continue;

            dates.push({
                deliveryDate: delivery,
                cutoffDate: cutoff,
                delivery: Utils.dateUntil(delivery, false),
                cutoff: Utils.dateUntil(cutoff, false)
            });
        }

        return dates;
    }

    getMinPallets() {
	    return parseInt(App.wholesaler.meta.get('min_order'));
    }

    _loadWholesalerInfo() {
        //return App.api.request("clients/" + App.user.details.client_id).then((data) => {
        console.log("Wholesaler setup");
        return App.api.request("wholesaler").then((data) => {
            console.log('wholesalerData', data);

            if(data == "error") return;
            //data = data.wholesaler;
            App.cache.setItem("wholesaler", data, (err) => { });
            this.details = data;
            this.ID = data.id;
            App.initUpdate('Wholesaler details', 1);


            // Get next day delivery dates
            Promise.all([SettingService.get("next_day_delivery"), SettingService.get("next_day_delivery_2")]).then((values) => {
                let dates = [];

                values.forEach((date, index) => {
                    if (!date) return;
                    date = new Date(date);

                    // Generate cutoff
                    let cutoff = new Date();
                    cutoff.setHours(12, 0, 0);

                    cutoff.setDate(cutoff.getDate() + index);

                    dates.push({
                        deliveryDate: date,
                        cutoffDate: cutoff,
                        delivery: Utils.dateUntil(date, false),
                        cutoff: Utils.dateUntil(cutoff, false)
                    });
                });

                this.nextDay = dates;
                console.log('this.nextDay', this.nextDay);
            });

        }, (error) => {
            console.log('eeeeeeeeeeeeeeeeeeeeeeeeeeee', error);
            App.initUpdate('Wholesaler details', 2);

            // Load from cache as offline
            App.cache.getItem("wholesaler", (err, data) => {
                this.details = data;
            });
        });
    }

}