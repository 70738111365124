import { AppService as App } from './app.js';

export default {
    get: function(name = false) {
        return this._load().then((settings) => {
            return settings[name];
        });
    },

    _load: function() {
        // Reuse the promise for future requests
        if (this.promise) {
            return this.promise;
        }

        this.promise = new Promise((resolve, reject) => {
            // Load content if we don't already have some cached
            App.api.request("settings").then((settings) => {
                console.log(settings);

                App.cache.setItem("settings", settings, (err) => { });
                this.settings = settings;

                resolve(this.settings);
            }, (error) => {
                console.error(error);

                // Load from cache as offline
                App.cache.getItem("settings", (err, settings) => {
                    this.settings = settings;

                    resolve(this.settings);
                });
            });
        });

        return this.promise;
    }
}