import React from 'react';
import { AppService as App } from '../../services/app.js';
import ContentBlock from "../includes/ContentBlock";
import {Col, Container, Row} from "react-bootstrap";

export default class Account extends React.Component {
    render() {
        return (
            <React.Fragment>
                <ContentBlock name="My account" type="background" />

                <Container>
                    <Row>
                        <Col>
                            <h3 id="page-title">My Account</h3>
                            <div className="block">
                                <div className="content spacing">
                                    <div>
                                        <p>
                                            <strong>Name:</strong>
                                            <br />
                                            {App.user.details.user.name}
                                        </p>
                                    </div>

                                    <div>
                                        <p>
                                            <strong>Email:</strong>
                                            <br />
                                            {App.user.details.user.email}
                                        </p>
                                    </div>

                                    <div>
                                        <p>
                                            <strong>My outlet details:</strong>
                                            <br />
                                            Account number {App.wholesaler.details.account_no}
                                        </p>
                                    </div>

                                    <div>
                                        <p>
                                            <strong>Address:</strong>
                                            <br />
                                            <strong>{App.wholesaler.details.name}</strong><br/>
                                            {App.wholesaler.details.address_line_1}<br/>
                                            {App.wholesaler.details.address_line_2}<br/>
                                            {App.wholesaler.details.city}<br/>
                                            {App.wholesaler.details.eircode}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
    );
  }
}