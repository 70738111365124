import { AppService as App } from './app.js';
import { Linking } from 'react';

export class HistoryService {

    getOrders() {
        return new Promise((resolve, reject) => {
            if (this.orders) {
                resolve(this.orders);
                return;
            }

            // If no history get some
            App.api.request("order/history").then((history) => {
                this.history = history;

                console.log('History service 1', this.history);

                App.cache.setItem("history", this.history, (err) => { });
                resolve(history);
            }, (error) => {
                console.error(error);

                // Load from cache as offline
                App.cache.getItem("history", (err, history) => {
                    this.history = history;
                    resolve(history);
                });
            });
        });
    }

    getOrder(orderID) {
        return this.getOrders().then((orders) => {
            // Do we have that order?
            let index = orders.findIndex(item => item.id === parseInt(orderID));

            if (index >= 0) {
                return orders[index];
            }

            // Lookup order
            return App.api.request("order/" + orderID);
        });
    }

    export(from = null, to = null) {
        var url = App.baseURI + '/api/order/export?pub_id=' + App.api.pub;

        if (from) {
            url += '&start=' + encodeURIComponent(from);
        }
        if (to) {
            url += '&end=' + encodeURIComponent(to);
        }

        console.log(url);
        Linking.openURL(url);
    }
}