import { AppService as App } from './app.js';
import { ApiService as Api } from './api.js';
import Fuse from 'fuse.js';

export class ProductsService {
    setup() {
        this.products = [];

        App.initUpdate('Product details', 0);
        return Api.request("products").then((products) => {
            this.products = products;

            this.products.sort((a, b) => (a.name > b.name) ? 1 : -1);

            App.initUpdate('Product details', 1);
            App.cache.setItem("products", products, (err) => { });
        }, (error) => {
            console.error(error);
            App.initUpdate('Product details', 2);
            
            // Load from cache as offline
            App.cache.getItem("products", (err, products) => {
                this.products = products;
            });
        });
    }

    get(values, key = null, includeInactive = false) {
        // Clone to avoid propagation
        let products = JSON.parse(JSON.stringify(this.products));

        if (!includeInactive) {
            // Filter out inactive
            products = products.filter(product => product.status !== "inactive");
        }

        if (!values) {
            return products;
        }

        if (!key) {
            key = "id";
        }

        if (!Array.isArray(values)) {
            values = [values];
        }

        var results = products.filter(product => {
            return values.includes(product[key]);
        });

        if (results.length == 1) {
            return results[0];
        } else if (results.length) {
            return results;
        }
    }

    getIcon(product) {
        return App.baseURI + '/' + product.image;
    }
}