import React from 'react';

import { AppService as App } from '../../services/app.js';
import rightArrowIcon from '../../assets/icons/SmartPub-arrow-right.svg';
import downArrowIcon from '../../assets/icons/SmartPub-arrow-down.svg';

function ButtonIcon(props){
  const hasIcon = ((props.iconType && props.iconName) ? true : false);
  const hasPath = (props.file ? true : false);
  const iconSize = "iconPlaceHolder" + props.size[0].toUpperCase() + props.size.substring(1, props.size.length);
  var file = typeof props.fileLocal !== "undefined" ? props.fileLocal : props.file;

  if(typeof props.fileLocal === "undefined"){
    file = {uri: file};
  }

  //console.log(file);

  const size = {
    "big": 60,
    "mediumBig": 56,
    "medium": 80,
    "checkout": 40,
    "small": 30,
    "smaller": 25,
    "tiny": 20
  }

  if(!hasIcon){
    if(!hasPath){
      return <div className={"icon placeholder "+ props.size}></div>
    } else {
      return <img src={file} alt="" className={"icon "+ props.size} />
    }
  } else {
    console.error("I don't know what is supposed to happen her");
  }
}

export class IconButton extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      hasIcon: true
    }

    var classes = ["btn btn-primary has-icon"];

    if (props.arrow) {
        classes.push("blank");
    } else {
        classes.push(props.theme ? props.theme : "green");
    }

    if (props.align) {
        classes.push(props.align);
    }
    if (props.size) {
        classes.push(props.size);
    } else {
        classes.push("medium");
    }

    if (props.className) {
        classes.push(props.className);
    }

    this.className = classes.join(' ');
  }

  render() {
    return (
      <a href={this.props.href || null} target={this.props.target || null} className={this.className} onClick={this.props.onClick}>
        <div className="row">
          <div className={"icon-wrapper " + (this.props.arrow ? (this.props.fileLocal || this.props.file ? "highlighted" : "blank") : null)}>
          {
            this.props.fileLocal || this.props.file ? 
              <ButtonIcon fileLocal={this.props.fileLocal} img={this.props.img} file={this.props.file} alt="" size={this.props.arrow ? "medium" : this.props.size} />
            :
              null
          }
          </div>

          <div className={"icon-text " + (!this.props.file && !this.props.fileLocal ? "blank " : "") + (this.props.arrow ? "col-md-9" : null)}>
            { this.props.title || this.props.text }
          </div>

          {
            this.props.arrow ?
              <div className={"icon-arrow col-md-2" + (!this.props.file && !this.props.fileLocal ? " blank" : "") + (this.props.open ? " open" : "")}>
              {
                !this.props.open ?
                    <img src={rightArrowIcon} alt="Right arrow" />
                :
                    <img src={downArrowIcon} alt="Down arrow" />
              }
              </div>
            :
              null
          }
        </div>
      </a>
    );
  }
}