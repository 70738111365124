import React from 'react';
import {Container} from "react-bootstrap";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import { AppService as App } from '../../services/app.js';
import { Utils } from '../../services/utils.js';
import {Link} from "react-router-dom";
import ContentBlock from "../includes/ContentBlock";
import SettingService from "../../services/settings";


export default class Home extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};

        App.wholesaler.history.getOrders().then(history => {
            this.setState({
                lastOrder: history.shift()
            })
        });

        // Get VAT
        SettingService.get("vat").then((vat) => {
            this.setState({
                vat: parseFloat(vat)
            })
        });
    }

    renderAccount() {
        let deliveryDates = App.wholesaler.getDeliveryDates();

        let delivery, cutoff;
        if (deliveryDates.length) {
            delivery = deliveryDates[0].delivery;
            cutoff = deliveryDates[0].cutoff;
        }


        return (
            <div className="block block--home">
                <Row>
                    <Col lg={true} className={"shipping " + delivery ? "border-right shipping" : ""}>
                        <div className="header">
                            <h4>Ship to address</h4>
                        </div>  
                        <div className="content">
                            <h5>{App.wholesaler.details.name}</h5>
                            {App.wholesaler.details.address_line_1}<br/>
                            {App.wholesaler.details.address_line_2}<br/>
                            {App.wholesaler.details.city}<br/>
                            {App.wholesaler.details.eircode}<br />
                            {App.wholesaler.details.account_no}
                        </div>
                    </Col>
                    {
                        delivery ? (
                            <Col lg={true} className="delivery">
                                <div className="header">
                                    <h4>Your next delivery date</h4>
                                </div>
                                <div className="content">
                                    <h5>{delivery}</h5><br/>
                                    <h6>Minimum order quantity: { App.wholesaler.getMinPallets() } Pallets</h6>

                                    <div className="note">
                                        For next day delivery order a full load before 12 noon. After 12 noon for a second- next day delivery
                                    </div>
                                </div>
                            </Col>
                        ) : ""
                    }
                </Row>
                <Row className="last">
                    <Col className="border-top">
                        <div className="footer">
                            <div className="btn btn--secondary btn--icon btn--cart" onClick={() => this.props.history.push("/order")}>Order now</div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    renderHistory() {
        if (!this.state.lastOrder) return "";

        var date = Utils.dateUntil(this.state.lastOrder.delivery_date, false);

        console.log(this.state.lastOrder);
        return (
            <div className="block block--home">
                <Row>
                    <Col className="history">
                        <div className="header">
                            <h4>Order history</h4>
                        </div>  
                        <div className="content">
                            <div>
                                <h5 className="order-number">Order N<sup>o</sup> {this.state.lastOrder.ref}</h5>
                                <p>{date}</p>
                            </div>

                            <div className="last">
                                <Link to="history" className="bold-link">&gt; VIEW ORDER HISTORY</Link>
                                <div className="total">
                                    <h5>
                                        {
                                            this.state.vat ?
                                                Utils.formatCurrency(this.state.lastOrder.total * (1 + this.state.vat))
                                            : ""
                                        }
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="last">
                    <Col className="border-top">
                        <div className="footer">
                            <div className="btn btn--secondary btn--icon btn--order" onClick={() => { App.wholesaler.order.createNew(this.state.lastOrder); this.props.history.push("/order")}}>Order again</div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    render() {
        let accountHTML = this.renderAccount(),
            historyHTML = this.renderHistory();

        return (
            <div id="home">
                <div className="hero">
                    <ContentBlock name="Home" type="image" />
                </div>
                <Container className="justify">
                    <div className="editable-content">
                        <ContentBlock name="Home"/>
                    </div>
                    <div className="home-nav">
                        <Row className="justify-content-center">
                            <Col lg={3}>
                                <div className="btn btn--primary btn--icon btn--order" onClick={() => this.props.history.push("/order")}>Order</div>
                            </Col>
                            <Col lg={3}>
                                <div className="btn btn--primary btn--icon btn--history" onClick={() => this.props.history.push("/history")}>Order history</div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            { accountHTML ? (<Col lg={true}>{ accountHTML }</Col>) : "" }
                            { historyHTML ? (<Col lg={true}>{ historyHTML }</Col>) : "" }
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }
}