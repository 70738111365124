import { AppService as App } from './app.js';

export default {
    get: function(name = false) {
        return this._load().then((content) => {
            return content ? content[name] : null;
        });
    },

    _load: function() {
        // Reuse the promise for future requests
        if (this.promise) {
            return this.promise;
        }

        this.promise = new Promise((resolve, reject) => {
            // Load content if we don't already have some cached
            App.api.request("content").then((content) => {
                console.log(content);

                App.cache.setItem("content", content, (err) => { });
                this.content = content;

                resolve(this.content);
            }, (error) => {
                console.error(error);

                // Load from cache as offline
                App.cache.getItem("content", (err, content) => {
                    this.content = content;

                    resolve(this.content);
                });
            });
        });

        return this.promise;
    }
}