import {AppService as App} from './app.js';
import axios from 'axios'

export class ApiService { 
    static setKey(key) {
        this.key = key;
        console.log('API key set', key);
    }

    static request(route, data = null, method = "GET") {
       /* if (!this.key) {
            throw "Missing API key";
        }*/

        var baseURL = App.baseURI + "/api";

        var url = `${baseURL}/${route}`;

        if (data && method === "POST") {
            var formData = new FormData();

            for (var key in data) {
                formData.append(key, data[key]);
            }

            // Clear data
            data = {};
        } else if (!data) {
            data = {};
        }

        data.version = App.version;
        console.log('Api request', App.user);
        if (App.user && App.user.getCurrentWholesaler()) {
            data.wholesaler_id = App.user.getCurrentWholesaler();
        }
        url += "?" + Object.entries(data).map(([key, val]) => `${key}=${val}`).join('&');

        console.log('apiUrl', url);
        
        var headers = {
            "Accept": "application/json"
        };

        if (this.key){
            headers["Authorization"] = "Bearer "+ this.key;
            //headers["Content-Type"] = "application/x-www-form-urlencoded";
        }

        return new Promise((resolve, reject) => {
            axios({
                url: url,
                method: method,
                withCredentials: false,
                data: formData ? formData : null,
                headers: headers,
                timeout: 30000
            }).then((response) => {
                /*
                if(route == 'route/here') {
                    console.log('response', response);
                }
                */
                resolve(response.data.data !== undefined ? response.data.data : response.data);
            }, (error) => {

                if (!error.response) {
                    reject("Network error: " + error.message);
                    return;
                }

                if (error.response.status === 401) {
                    var message = "You have been logged out. Please login again to access Starlink";

                    alert(message);
                    App.user.logout();

                    return;
                } else if (error.response.data && error.response.data.message) {
                    if(error.response.data.message.toLowerCase().includes("access denied")){
                        console.log('denied');
                        var preUser = App.user.isInactive();
                        App.user.setInactive();

                        if(!preUser) window.location.reload();

                        resolve("error");
                        return;
                    }

                    console.log('apiError1', error);
                    reject(error.response.data.message);
                } else if (error.response.status === 500) {
                    console.log('apiError2', error);
                    reject("Server error: unhandled PHP error");
                } else {
                    console.log('apiError3', error);
                    reject("Server error: " + error.response.status);
                }
            })
        })
    }
}