import React from 'react';
import ContentService from "../../services/content";

export default class ContentBlock extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            type: this.props.type ?? "html",
            content: {}
        }

        this.getContent(props.name);
    }

    componentWillReceiveProps(nextProps) {
        this.getContent(nextProps.name);

        this.setState({
            type: this.props.type ?? "html",
            content: {}
        });
    }

    getContent(name) {
        ContentService.get(name).then((content) => {
            this.setState({
                content
            })
        });
    }

    render() {
        if (!this.state.content) return "";

        if (this.state.type == "image") {
            return <img src={this.state.content.image}/>;
        }

        if (this.state.type == "background") {
            return <div className="banner"><div className="ratio-container" style={{backgroundImage: "url(" + this.state.content.image + ")"}}></div></div>;
        }

        return <div  dangerouslySetInnerHTML={{ __html: this.state.content.html }}></div>
    }
}