import { AppService as App } from './app.js';

export class WholesalerMetaService {
    constructor() {
        this.meta = [];
    }

    setup() {
        App.initUpdate('Wholesaler meta', 0);
        return this._load();
    }

    get(name = false) {
        if (!this.meta) {
            return null;
        }

        if (!name) {
           return this.meta;
        }

        return this.meta[name];
    }

    _load() {
        return App.api.request("wholesaler/metadata").then((meta) => {
            console.log(meta);

            App.initUpdate('Wholesaler meta', 1);
            App.cache.setItem("wholesalermeta", meta, (err) => { });
            this.meta = meta;
        }, (error) => {
            console.error(error);
            App.initUpdate('Wholesaler meta', 2);

            // Load from cache as offline
            App.cache.getItem("wholesalermeta", (err, data) => {
                this.meta = data;
            });
        });
    }
}