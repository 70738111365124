import { AppService as App } from './app.js';
import { ApiService as Api } from './api.js';

export class UserService { 
    setup() {
        App.initUpdate('User details', 0);
        return this._loadUser();
    }

    isLoggedIn() {
        return !!this.details;
    }

    login(email, password) {
        if (this.isLoggedIn()) {
            return false;
        }

        var data = {
            email: email,
            password: password
        };

        return App.api.request("login", data, "POST").then((userData) => {
            // Store user details in cache
            App.cache.setItem("user", userData, (err) => { });

            App.api.setKey(userData.access_token);

            // Setup user
            this.setup();

            // Send event to analytics
            App.analytics.loggedIn();

            return data;
        });
    }

    logout() {
        App.analytics.logEvent('logout');
        App.cache.clearAll((err) => {
            window.location.reload();
        });
    }

    request(email) {
        if (this.isLoggedIn()) {
            return false;
        }

        var data = {
            email: email
        };

        return App.api.request("requestPassword", data, "POST").then((data) => {
            return data;
        });
    }

    hasAcceptedTerms() {
        return new Promise((resolve, reject) => {
            return App.api.request("terms").then(data => {
                if (data.accepted) {
                    this.details.acceptedTerms = true;
                    this.save();
                    resolve();
                } else {
                    this.details.acceptedTerms = false;
                    this.save();
                    reject(data.terms);
                }
            }, error => {
                if (this.details.acceptedTerms) {
                    resolve();
                }
            })
        });
    }

    acceptTerms() {
        return App.api.request("terms/accept", null, "POST").then(data => {
            this.details.acceptedTerms = true;
            this.save();
        });
    }

    getWholesalers() {
        if (this.details.wholesalers && this.details.wholesalers.length) {
            return this.details.wholesalers;
        }
    }

    setCurrentWholesaler(wholesalerID) {
        this.details.currentWholesaler = parseInt(wholesalerID);

        App.cache.setItem("user", this.details, () => { });
    }

    getCurrentWholesaler() {
        if (!this.details) return null;
        if (this.details.currentWholesaler) {
            return this.details.currentWholesaler;
        } else {
            return this.details.wholesalers[0].id;
        }
    }

    save() {
        App.cache.setItem("user", this.details, (err) => { });
    }

    isInactive(){
        return this.details && this.details.inactive ? this.details.inactive : false;
    }

    setInactive(){
        this.details.inactive = true;

        App.cache.setItem("user", this.details, () => {});
    }

    _loadUser() {
        return new Promise((resolve, reject) => {
            // Load user data from cache
            App.cache.getItem("user", (err, cachedDetails) => {
                if (cachedDetails) {
                    App.api.setKey(cachedDetails.access_token);

                    this.details = cachedDetails;
                    this.ID = cachedDetails.user.id;

                    if (cachedDetails.inactive) resolve();

                    App.api.request("user").then((newDetails) => {
                        newDetails.access_token = cachedDetails.access_token;
                        newDetails.currentWholesaler = cachedDetails.currentWholesaler;

                        if (cachedDetails.acceptedTerms) {
                            newDetails.acceptedTerms = cachedDetails.acceptedTerms;
                        }

                        this.details = newDetails;
                        App.cache.setItem("user", newDetails, (err) => { });

                        if (!this.details.wholesalers || !this.details.wholesalers.length) {
                            reject("User not assigned to wholesaler");
                            this.logout();
                            return;
                        }

                        App.initUpdate('User details', 1);

                        resolve(this.details);
                    }, (e) => {
                        console.log(e);

                        if (!this.details.wholesalers || !this.details.wholesalers.length) {
                            throw Error("User not assigned to wholesaler2");
                        }

                        App.initUpdate('User details', 2);

                        resolve(this.details);
                    });
                } else {
                    reject();
                }
            });
        })
    }
}
