import React from 'react';

import { AppService as App } from '../../services/app.js';
import Button from "./Button";
import {OverlayTrigger, Popover} from "react-bootstrap";

import AlertIcon from '../../assets/starlink/icons/alert-icon.svg';
import {Utils} from "../../services/utils";

export class ProductItem extends React.Component {

    constructor(props) {
        super(props);

        let product = App.products.get(this.props.item.id, null, true);

        let inOrder;
        if (product) {
            inOrder = App.wholesaler.order.get(product.id);
        }

        let qty = 0;
        if (this.props.item.qty) {
            qty = this.props.item.qty;
        } else if (inOrder) {
            qty = inOrder.quantity;
        }

        this.state = {
            product: product,
            qty: qty,
            columns: this.props.columns
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.addInputChange = this.addInputChange.bind(this);
        this.minusInputChange = this.minusInputChange.bind(this);
    }

    handleInputChange(event) {
        let qty = parseInt(event.target.value);

        this.props.onUpdate(this.state.product.id, qty);

        this.setState({
            qty: qty
        });
    }

    addInputChange() {
        let qty = this.state.qty + 1;

        this.props.onUpdate(this.state.product.id, qty);

        this.setState({
            qty: qty
        });
    }

    minusInputChange() {
        let qty = this.state.qty - 1;

        if (qty >= 0) {
            this.props.onUpdate(this.state.product.id, qty);
            this.setState({
                qty: qty
            });
        }

    }



    render() {
        if (!this.state.product) {
            return null;
        }

        let orderProducts;
        if (this.props.history) {
            orderProducts = this.props.history.products;
        }

        var columns = [];
        this.state.columns.forEach((key) => {
            let html = '';

            switch (key) {
                case 'image':
                    html = <img src={App.products.getIcon(this.state.product)}/>;
                    break;
                case 'price':
                    html = Utils.formatCurrency(this.state.product.price);
                    break;
                case 'total':
                    html = Utils.formatCurrency(App.wholesaler.order.getTotal(this.props.item.id, orderProducts));
                    break;
                case 'pallets':
                    html = App.wholesaler.order.getPallets(this.props.item.id, orderProducts);
                    break;
            }

            if (key === 'title') {
                html = <p>{ this.state.product.name }</p>;

                // If we're not on a history page and there is a special offer or notes add to the title
                if (!this.props.history && this.state.product.metadata && (this.state.product.metadata.special_offer || this.state.product.metadata.notes)) {
                    html = (
                        <React.Fragment>
                            {html}
                            { this.state.product.metadata.special_offer ?
                                <p><small>{this.state.product.metadata.special_offer}</small></p>
                            : null }
                            { this.state.product.metadata.notes ?
                                <p><small>{this.state.product.metadata.notes}</small></p>
                            : null }
                        </React.Fragment>
                    );
                }
            }

            if (key === 'qty') {
                if (this.props.item.qty) {
                    html = <span>{this.props.item.qty}</span>;
                } else if (this.state.product.status === "active") {
                    html = ( 
                        <div className="number-input">
                            <Button onClick={this.minusInputChange} disableTab>-</Button>
                            <input type='text' value={this.state.qty > 0 ? this.state.qty : ""} placeholder="0" onChange={this.handleInputChange}></input>
                            <Button onClick={this.addInputChange} disableTab>+</Button>
                        </div>
                    );
                } else {
                    html = <span>Out of stock</span>;
                }
            }

            if (key === 'info') {
                html = (
                    <OverlayTrigger
                        trigger="hover"
                        placement="auto"
                        overlay={
                            <Popover>
                                <Popover.Content>
                                    <strong>Cases per container:</strong> { this.state.product.metadata.cases_per_container }<br/>
                                    <strong>Cases per layer:</strong> { this.state.product.metadata.cases_per_layer }<br/>
                                    <strong>Layers per pallet:</strong> { this.state.product.metadata.layers_per_pallet }<br/>
                                    <strong>Cases per pallet:</strong> { this.state.product.metadata.cases_per_pallet }<br/>
                                    <strong>Pallets per container:</strong> { this.state.product.metadata.pallets_per_container }
                                </Popover.Content>
                            </Popover>
                        }
                    >
                        <img src={AlertIcon} className="icon"/>
                    </OverlayTrigger>
                )
            }

            columns.push(<td className="align-middle">{html}</td>);
        });

        return (
            <tr>
                {columns}
            </tr>
        )
    }

}