export class Utils {

    static dateUntil(date, time = true) {
        if (!date) return "No date set";

        var date = new Date(date);

        var month = Utils.monthToString(date.getMonth()),
            day  = Utils.dayToString(date.getDay()) + ' ' + (date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()),
            hour = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours(),
            minute = date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes();

        var dateStr = day + ' ' + month,
            timeStr = hour + ':' + minute;

        // Is date today
        if (date.toDateString() === (new Date()).toDateString()) {
            dateStr = 'Today';
        }

        // Is date tomorrow
        var tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1);
        if (date.toDateString() === tomorrow.toDateString()) {
            dateStr = 'Tomorrow';
        }

        // Is date yesterday
        var yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1);
        if (date.toDateString() === yesterday.toDateString()) {
            dateStr = 'Yesterday';
        }

        if (hour == '00') {
            return dateStr;
        }
        if (time)
            return dateStr + ' ' + timeStr;
        else
            return dateStr;
    }

    static getDateString(date) {
        if (!(date instanceof Date)) {
            date = new Date(date);
        }

        var year = date.getFullYear(),
            month = date.getMonth() + 1,
            day  = date.getDate();

        // Pad month and day
        month = month >= 10 ? month : '0' + month;
        day = day >= 10 ? day : '0' + day;

        return year + '-' + month + '-' + day;
    }

    static getYear(date) {
        if (!(date instanceof Date)) {
            date = new Date(date);
        }

        var year = date.getFullYear()

        return year;
    }

    static isDateBeforeToday(date) {
        return new Date(new Date(date).toDateString()) < new Date(new Date().toDateString());
    }

    static formatDateRange(start, end) {
        var one_day = ((24*60)*60)*1000,
            startStr,
            endStr;

        start = new Date(start);
        end = new Date(end);

        // Is date today
        if (start.toDateString() === (new Date()).toDateString()) {
            startStr = 'Today';
        }
        if (end.toDateString() === (new Date()).toDateString()) {
            endStr = 'Today';
        }

        // Is date tomorrow
        var tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1);
        if (start.toDateString() === tomorrow.toDateString()) {
            startStr = 'Tomorrow';
        }
        if (end.toDateString() === tomorrow.toDateString()) {
            endStr = 'Tomorrow';
        }

        // Is date yesterday
        var yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1);
        if (start.toDateString() === yesterday.toDateString()) {
            startStr = 'Yesterday';
        }
        if (start.toDateString() === yesterday.toDateString()) {
            endStr = 'Yesterday';
        }

        if (!endStr) {
            endStr = end.getDate() + " " + Utils.monthToString(end.getMonth()) + "  " + end.getFullYear()
        }

        // Is the event a single day?
        if (end - start < one_day) {
            return startStr ? startStr : start.getDate() + " " + Utils.monthToString(start.getMonth()) + "  " + start.getFullYear();
        } else {
            if (this.isDateBeforeToday(start) || start.toDateString() === (new Date()).toDateString()) {
                return "Until " + endStr;
            }

            if (end.getMonth() !== start.getMonth()) {
                if (!startStr) {
                    startStr = start.getDate() + " " + Utils.monthToString(start.getMonth());
                }
                return startStr + " - " + endStr;
            } else {
                if (!startStr) {
                    startStr = start.getDate();
                }
                return startStr + " - " + endStr;
            }
        }
    }

    static monthToString(month) {
        var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return months[month];
    }

    static dayToString(day) {
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return days[day];
    }

    static formatCurrency(value) {
        return '€' + parseFloat(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

}