import { AppService as App } from './app.js';

import firebase from "firebase/app";
import "firebase/analytics";

export class AnalyticsService {
	static getInstance() {
		console.log('Setup firebase');
		if (AnalyticsService.analytics) return AnalyticsService.analytics;

		console.log('Setup firebase 2');
		firebase.initializeApp(App.firebaseConfig);
		AnalyticsService.analytics = firebase.app().analytics();

		return AnalyticsService.analytics;
	}

	static setScreen(name) {
		console.log('Set web screen', name);
		AnalyticsService.getInstance().setCurrentScreen(name);
		AnalyticsService.getInstance().logEvent('screen_view', { screen_name : name }); // Send event
	}

	static logEvent(name, params) {
		console.log('Log event', name, params);
		AnalyticsService.getInstance().logEvent(name, params); // Send event
	}

	static setUser(userID, role) {
		if (userID) {
			AnalyticsService.getInstance().setUserId(userID);
		}
		if (role) {
			AnalyticsService.getInstance().setUserProperties({role: role});
		}
	}

	static loggedIn() {
		console.log('Log login');
		AnalyticsService.getInstance().logEvent('login', {
			method: 'login'
		});
	}

}