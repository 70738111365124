import React from 'react';
import { Link } from "react-router-dom";

import { AppService as App }  from '../../services/app.js';
import {Badge, Nav, Navbar, NavDropdown} from "react-bootstrap";
import FlatList from "flatlist-react";

export class Header extends React.Component {
	constructor(props) {
        super(props);

        this.state = {
            active: false
        }

        // Callback to update product count
        this.callback = () => {
            this.setState({});
        };
    }

    componentDidMount() {
	    App.wholesaler.order.onUpdate(this.callback);
    }

    componentWillUnmount() {
        App.wholesaler.order.offUpdate(this.callback);
    }

	render(){
	    if (!App.user) return "";

	    return (
            <Navbar expand="lg" variant="dark">
                <Navbar.Brand as={Link} to="/home" className="logo">Starlink</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto align-items-center">
                        <Nav.Link as={Link} to="/home">Home</Nav.Link>
                        <Nav.Link as={Link} to="/account">My Account</Nav.Link>
                        <Nav.Link as={Link} to="/order">Order</Nav.Link>
                        <Nav.Link as={Link} to="/history">Order history</Nav.Link>
                        <Nav.Link as={Link} to="/page/collections">Amendments and Collections</Nav.Link>
                        <Nav.Link as={Link} to="/page/about">About HEINEKEN</Nav.Link>
                        <Nav.Link as={Link} to="/feedback">Contact us</Nav.Link>
                    </Nav>
                    <Nav className="align-items-center">
                        <NavDropdown title={'Hi ' + App.user ? App.user.details.user.name : "" } className="mr-4" alignRight>
                            <FlatList
                                list={App.user.getWholesalers().filter(item => item.id !== App.user.getCurrentWholesaler())}
                                renderItem={
                                    (item) => (
                                        <NavDropdown.Item as={Link} to={"/init/" + item.id}>Change to {item.name}</NavDropdown.Item>
                                    )
                                }
                                renderWhenEmpty={() => null}
                                keyExtractor={item => item.id}
                            />
                            <NavDropdown.Item href="#" onClick={App.user.logout}>Logout</NavDropdown.Item>
                        </NavDropdown>

                        <Nav.Link as={Link} to="/order/review" className="cart">
                            {
                                App.wholesaler.order.getProductCount() > 0 ?
                                    <Badge pill  variant="danger">{ App.wholesaler.order.getProductCount() }</Badge>
                                : ""
                            }
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
	}
}