import React from 'react';

import {AppService as App} from '../../services/app.js';
import ContentBlock from "../includes/ContentBlock";
import FlatList from "flatlist-react";
import {ProductItem} from "../includes/ProductItem";
import {ButtonGroup, Col, Container, Nav, Row, Table, ToggleButton} from "react-bootstrap";
import Button from "../includes/Button";
import {Utils} from "../../services/utils";
import SettingService from "../../services/settings";

export default class Order extends React.Component {
    constructor(props) {
        super(props);

        this.updateQuantity = this.updateQuantity.bind(this);
        this.submitOrder = this.submitOrder.bind(this);
        this.setDeliveryDate = this.setDeliveryDate.bind(this);

        let deliveryDates = this.getDeliveryDates();

        this.state = {
            products: App.wholesaler.order.get(),
            deliveryDates: deliveryDates,
            delivery: deliveryDates[0],
            userReference: "",
            deliveryNote: ""
        }

        // Check order is valid
        let orderMsg = App.wholesaler.order.check();

        if (orderMsg) {
            alert(orderMsg);
            this.props.history.push({
                pathname: "/order"
            });
            return;
        }

        // Get VAT
        SettingService.get("vat").then((vat) => {
            this.setState({
                vat: parseFloat(vat)
            })
        });
    }

    getDeliveryDates() {
        let deliveryDates = App.wholesaler.getDeliveryDates(),
            i = 0;

        deliveryDates.forEach((date, index) => {
            date.index = index;
        });

        return deliveryDates;
    }

    updateQuantity(product, quantity) {
        App.wholesaler.order.update(product, quantity);

        this.setState({
            products: App.wholesaler.order.get()
        });

        let deliveryDates = this.getDeliveryDates();
        if (deliveryDates.length !== this.state.deliveryDates.length) {
            this.setState({
                deliveryDates: deliveryDates,
                delivery: deliveryDates[0]
            });
        }
    }

    submitOrder() {
        if (this.state.loading) return;

        // Check if delivery date cutoff has passed
        if (this.state.delivery) {
            let deliveryCheckMessage = App.wholesaler.order.checkDeliveryDate(this.state.delivery.cutoffDate);
            if (deliveryCheckMessage !== true) {
                alert(deliveryCheckMessage);
                return;
            }
        }

        var confirm = window.confirm('Pressing this button will create your order.\n\nYou will not be able to amend any of the items on this page.\n\nAre you sure you want to continue?');
        if (confirm) {
            console.log("Order submitted");

            this.setState({
                loading: true
            });

            App.wholesaler.order.submit(this.state.userReference, this.state.deliveryNote, this.state.delivery ? this.state.delivery.delivery : null).then((data) => {
                console.log("order data", data);

                this.props.history.push({
                    pathname: "/history/order/" + data.id
                });
            }, (error) => {
                alert("Error submitting order: " + error);
            }).finally(() => {
                this.setState({
                    loading: false
                });
            });
        }
    }

    setDeliveryDate(index) {
        this.setState({
            delivery: this.state.deliveryDates[index]
        });
    }

    render() {
        let orderValid = !App.wholesaler.order.check(),
            containerStatus = App.wholesaler.order.getContainerStatus();

        return (
            <div id="order-new">
                <ContentBlock name="Order" type="background" />
                <Container>
                    <Row>
                        <Col>
                            <h3 id="page-title">Please check your order to submit</h3>
                            <div className="block">
                                <div className="content p-4">
                                    <Row>
                                        <Col>
                                            <div className="address icon">
                                                <h5>Delivery address:</h5>
                                                {App.wholesaler.details.name}<br/>
                                                {App.wholesaler.details.address_line_1}<br/>
                                                {App.wholesaler.details.address_line_2}<br/>
                                                {App.wholesaler.details.city}<br/>
                                                {App.wholesaler.details.eircode}
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="reference icon">
                                                <h5>Your reference:</h5>
                                                <p className="min-height"><strong>You can provide your own reference here</strong></p>
                                                <textarea onChange={(e) => this.setState({userReference: e.target.value})}>{this.state.userReference}</textarea>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="notes icon">
                                                <h5>Your delivery notes:</h5>
                                                <p className="min-height"><strong>If required add a note to this order and our drivers will do their best to facilitate your request</strong></p>
                                                <textarea onChange={(e) => this.setState({deliveryNote: e.target.value})}>{this.state.deliveryNote}</textarea>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9}>
                            <h3 class="page-title">Delivery date</h3>

                            {
                                !containerStatus.nextDay && App.wholesaler.order.getPallets() >= 16 ?
                                    <div className="notice notice--delivery-cta">
                                        Do you want to add some more product to your cart for "next day delivery?"
                                        <Button
                                            title="Adjust cart"
                                            className="edit"
                                            onClick={() => this.props.history.push('/order')}
                                        />
                                    </div>
                                : null
                            }
                            <ButtonGroup toggle>
                                {this.state.deliveryDates.map((date, index) => {
                                    if (new Date(date.cutoffDate) < new Date()) return;
                                    return (
                                        <ToggleButton
                                            type="radio"
                                            variant="secondary"
                                            name="radio"
                                            value={index}
                                            checked={this.state.delivery.index == index}
                                            onChange={(e) => this.setDeliveryDate(e.currentTarget.value)}
                                        >
                                            {date.delivery}
                                        </ToggleButton>
                                    )
                                })}
                            </ButtonGroup>

                            {
                                !containerStatus.nextDay ?
                                    <div className="notice notice--delivery">For next day delivery order a full load before 12 noon. After 12 noon for a second-next day delivery</div>
                                : null
                            }
                        </Col>
                        <Col>
                            <div className="block">
                                <div className="content p-4">
                                    <ContentBlock name="Order sidebar 1"/>
                                </div>
                            </div>
                            <div className="block">
                                <div className="content p-4">
                                    <ContentBlock name="Order sidebar 2"/>
                                </div>
                            </div>
                        </Col>
                    </Row>


                    <Row>
                        <Col>
                            <div className="block">
                                <Table striped borderless responsive>
                                    <thead>
                                    <tr>
                                        <th colspan="2">Your products</th>
                                        <th>Price</th>
                                        <th>Order quantity</th>
                                        <th colspan="2">Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <FlatList
                                        list={this.state.products}
                                        renderItem={
                                            (item) => (
                                                <ProductItem
                                                    item={item}
                                                    columns={['image', 'title', 'price', 'qty', 'total', 'remove']}
                                                    onUpdate={this.updateQuantity}
                                                />
                                            )
                                        }
                                        keyExtractor={item => item.id}
                                    />
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <h3 class="page-title">Summary</h3>
                            <div className="block block--summary">
                                <div className="content p-4">
                                    <Row className="align-items-center">
                                        <Col className="align-middle">
                                            <Button
                                                title="Edit order"
                                                theme="primary"
                                                className="edit"
                                                onClick={() => this.props.history.push('/order')}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Table size="sm" borderless responsive>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        Minimum order quantity:
                                                    </td>
                                                    <td>
                                                        { App.wholesaler.getMinPallets() } Pallets
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Total order quantity:
                                                    </td>
                                                    <td className={!orderValid ? "text-danger" : "" }>
                                                        { App.wholesaler.order.getPallets() } pallets
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Sub total:
                                                    </td>
                                                    <td>
                                                        { Utils.formatCurrency(App.wholesaler.order.getTotal()) }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Vat ({ this.state.vat * 100 }%):
                                                    </td>
                                                    <td>
                                                        { Utils.formatCurrency(App.wholesaler.order.getTotal() * this.state.vat) }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Total price:</strong>
                                                    </td>
                                                    <td>
                                                        <strong>
                                                            { Utils.formatCurrency(App.wholesaler.order.getTotal() * (1 + this.state.vat)) }
                                                        </strong>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col>
                                            <Button
                                                title="Place order"
                                                theme="secondary"
                                                className="btn--order btn--icon"
                                                onClick={() => this.submitOrder()}
                                                disabled={!orderValid}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
