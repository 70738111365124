import React from 'react';

import Button  from '../includes/Button';
import { FeedbackService } from '../../services/feedback.js';
import ContentBlock from "../includes/ContentBlock";
import {Container, Col, Row} from "react-bootstrap";

import { showMessage } from '../facades/showMessage.js';

export default class Feedback extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            comment: null,
            submitted: false,
            loading: false,
            requestType: null
        }

        this.submitFeedback = this.submitFeedback.bind(this);
    }

    submitFeedback() {
        if (this.state.loading) {
            return;
        }

        var response = FeedbackService.submit(this.state.comment);

        if (Promise.resolve(response) != response) {
            showMessage(response);
            return;
        }

        this.setState({
            loading: true
        });

        response.then((data) => {
            this.setState({
                loading: false,
                submitted: true
            });
        }, (error) => {
            this.setState({
                loading: false
            });

            alert('Error submitting feedback: ' + error);
        });
    }

    render() {
        return (
            <div id="feedback">
                <ContentBlock name="Order" type="background" />
                <Container>
                    <Row>
                        <Col>
                            <h3 id="page-title">Contact us</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={true}>
                            <div className="block p-4 block--contact block--cs">
                                <ContentBlock name="Contact us" />
                            </div>
                        </Col>
                        <Col lg={true}>
                            <div className="block p-4 block--contact">
                                <h1>Contact us</h1>
                                <p>Please drop us a line with the details on your query. We will get back to you asap within normal business hours. Thank you.</p>
                                {
                                    this.state.submitted ?
                                        <h5 className="text-center">
                                            Thank you for your feedback
                                        </h5>
                                    :
                                        <React.Fragment>
                                            <textarea
                                                id="feedback-text"
                                                placeholder="Your comment here"
                                                onChange={(e) => this.setState({comment: e.target.value})}
                                            >{this.state.comment}</textarea>

                                            <Button
                                                title="Submit"
                                                theme=""
                                                textColor="white"
                                                loading={this.state.loading}
                                                onClick={() => this.submitFeedback()}
                                            />

                                        </React.Fragment>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}