import {AppService as App} from './app.js';
import {Utils} from "./utils";
import React from "react";

export class OrderService {

    update(productID, quantity) {
        if (!App.products.get(productID)) {
            console.error('Product doesn\'t exist', productID);
            return;
        }

        quantity = parseInt(quantity);
        if (!quantity || quantity < 0) {
            quantity = 0;
        }

        var index = this.order.findIndex(item => item["id"] === productID);

        if (index >= 0) {//
            if (quantity > 0) {
                // Update order quantity
                this.order[index].quantity = quantity;
            } else {
                // Remove item from order
                this.order.splice(index, 1);
            }
        } else if (quantity > 0) {
            this.order.push({
                id: productID,
                quantity: quantity
            })
        }

        console.log(this.order);

        if (this.callbacks) {
            this.callbacks.forEach((callback) => {
                callback();
            })
        }
    }

    remove(productID) {
        this.update(productID, null);
    }

    // Allow components to hook into order updates
    onUpdate(callback) {
        if (typeof this.callbacks === 'undefined') {
            this.callbacks = [];
        }

        this.callbacks.push(callback);

        console.log('Callbacks 1', this.callbacks.length);
    }

    offUpdate(callback) {
        const removeIndex = this.callbacks.findIndex(fn => {
            return fn === callback;
        });

        console.log("Remove callback", removeIndex);

        if (removeIndex !== -1) {
            this.callbacks = this.callbacks.slice(removeIndex, 1);
        }


        console.log('Callbacks 2', this.callbacks.length);
    }

    getProductCount() {
        if (!this.order || !this.order.length) return 0;

        return this.order.reduce((total, item) => total + parseInt(item.quantity), 0);
    }

    getPallets(productID = null, order = null) {
        if (!order) {
            order = this.order;
        }

        if (productID) {
            order = order.filter(item => item.id == productID);
        }

        if (!order || !order.length) return 0;

        return order.reduce((total, item) => {
            let quantity = item.quantity ? item.quantity : item.qty; // Current order and history order use different variable names

            if (quantity == 0 || !App.products.get(item.id)) return 0;

            let metadata = App.products.get(item.id).metadata;

            if (!metadata || !metadata.cases_per_layer || !metadata.layers_per_pallet) {
                return 0;
            }

            let pallets = quantity / (metadata.cases_per_layer * metadata.layers_per_pallet);

            return total + pallets;
        }, 0).toFixed(1);
    }

    getContainerStatus(productID = null, order = null) {
        if (!order) {
            order = this.order;
        }

        if (productID) {
            order = order.filter(item => item.id == productID);
        }

        if (!order || !order.length) return 0;

        let pallets = 0,
            palletsCans = 0,
            palletsBottles = 0;

        order.forEach(item => {
            let product = App.products.get(item.id),
                quantity = item.quantity;

            if (quantity == 0 || !product) return 0;

            let metadata = product.metadata;

            if (!metadata || !metadata.cases_per_layer || !metadata.layers_per_pallet) {
                return 0;
            }

            let p = quantity / (metadata.cases_per_layer * metadata.layers_per_pallet);

            pallets += p;

            if (product.purchase_UOM === "bottles") {
                palletsBottles += p;
            } else {
                palletsCans += p;
            }
        }, 0);

        console.log('Pallets', pallets, palletsCans, palletsBottles);

        let status = {
            nextDay: false,
            full: false,
            overfull: false
        };


        if (palletsBottles && !palletsCans) {
            if (palletsBottles > 23.5) {
                status.nextDay = true;
            }
            if (palletsBottles >= 24) {
                status.full = true;
            }
            if (palletsBottles > 24) {
                status.overfull = true;
            }
        } else if (palletsCans && !palletsBottles) {
            if (palletsCans > 21.5) {
                status.nextDay = true;
            }
            if (palletsCans >= 22) {
                status.full = true;
            }
            if (palletsCans > 22) {
                status.overfull = true;
            }
        } else {
            if (palletsCans + palletsBottles > 21.5) {
                status.nextDay = true;
            }
            if (palletsCans + palletsBottles >= 24) {
                status.full = true;
            }
            if (palletsCans + palletsBottles > 24 || palletsCans > 21) {
                status.overfull = true;
            }
        }

        return status;
    }

    getTotal(productID = null, order = null) {
        if (!order) {
            order = this.order;
        }

        if (productID) {
            order = order.filter(item => item.id == productID);
        }

        if (!order || !order.length) return 0;

        console.log(order);

        return order.reduce((total, item) => {
            let quantity = item.quantity ? item.quantity : item.qty; // Current order and history order use different variable names

            if (quantity == 0) return 0;

            let price = App.products.get(item.id).price;

            let cost = quantity * price;

            return total + cost;
        }, 0).toFixed(2);
    }

    check() {
        if (!this.order) {
            return "Your order does not contain any products.\n\nYour order must contain at least one product in order to proceed.";
        }

        let order = this.order.filter(item => item.quantity);

        if (!order.length) {
            return "Your order does not contain any products.\n\nYour order must contain at least one product in order to proceed.";
        }

        if (this.getPallets() < App.wholesaler.getMinPallets()) {
            return "You have not reached the minimum order quantity";
        }

        let containerStatus = App.wholesaler.order.getContainerStatus();
        if (containerStatus.overfull) {
            return "You have exceeded the maximum order quantity";
        }
    }

    submit(reference, note, date) {
        // Push to server
        return this._save(reference, note, date).then((data) => {
            delete this.order;
            App.analytics.logEvent('order_submitted');

            return data;
        });
    }

    get(value, key) {
        if (!this.order) {
            this.order = [];
        }

        // Clone to avoid propagation
        let order = JSON.parse(JSON.stringify(this.order));

        if (!value) {
            // Remove line items with 0 quantity
            order = order.filter((item) => item.quantity > 0);

            return order;
        }

        if (!key) {
            key = 'id';
        }

        let index = order.findIndex(item => item[key] === value);

        if (index >= 0) {//
            return order[index];
        }
    }

    clearCurrent() {
        this.order = null;
    }

    createNew(historicalOrder = false) {
        App.analytics.logEvent('order_new', {type: historicalOrder ? 'history' : 'normal'});

        this.order = [];

        if (historicalOrder && historicalOrder.products && historicalOrder.products.length) {
            historicalOrder.products.forEach((product) => {
                this.update(product.id, product.qty);
            });
        }

        return this.order;
    }

    checkDeliveryDate(cutoffDate) {
        var cutoff = new Date(cutoffDate),
            now = new Date();

        if (cutoff < now) {
            return "You have missed the cutoff for this delivery date";
        }

        return true;
    }

    _save(reference, note, date) {
        // Remove line items with 0 quantity
        var order = this.order.filter((item) => {
            return item.quantity > 0;
        });

        console.log("ORDER", order);

        var data = {
            'order': JSON.stringify(order),
            'notes': note,
            'reference': reference
        };

        if (date) {
            data.delivery = date;
        }

        console.log('data', data)

        return App.api.request("order", data, "POST").then((data) => {
            return data;
        }, (error) => {
            console.log('Ordering error', error);
            throw error;
        });
    }
}